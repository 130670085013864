.podcast-page {
    background-color: var(--primary-color);
    min-height: 100vh;
    padding-top: 80px;
}

/* Hero Section */

.podcast-hero {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 110px 0 60px 0;
    /* padding: 60px 0; */
}

.hero-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.hero-image {
    width: 45%;
    height: 450px;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
}


.hero-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.hero-text {
    width: 50%;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: bold;
}

p {
    font-size: 1rem;
    margin-bottom: 30px;
}

.subscribe-form {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}


.subscribe-form input {
    flex-grow: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
}


.subscribe-form button {
    font-weight: 600;
    padding: 10px 20px;
    background-color: var(--secondary-color);
    color: var(--light-color);
    border: 2px solid var(--light-color);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}


.subscribe-form button:hover {
    background-color: var(--light-color);
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
}


/* Empowering Voice Section */

.empowering-voice {
    background-color: var(--primary-color);
    color:var(--light-color);
    padding: 60px;
}

.empowering-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.empowering-text {
    width: 60%;
}

.empowering-text h2 {
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: bold;
    color: var(--light-color);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.empowering-text p {
    font-size: 1.2rem;
    line-height: 1.6;
}

.empowering-image {
    width: 35%;
    height: 300px;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
}


.empowering-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

/* CATEGORIES SECTION */

.top-categories {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 50px;
}

.categories-content {
    max-width: 1200px;
    margin: 0 auto;
}

.top-categories h2 {
    font-size: 3rem;
    margin-bottom: 40px;
    font-weight: bold;
    text-align: center;
    color: var(--light-color);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.categories-list {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.category-item {
    background-color: var(--light-color);
    border-radius: 15px;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    gap: 15px;
    flex: 1;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.category-icon {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    border: 2px solid var(--secondary-color);
}

.category-item span {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--secondary-color);
}

/* All Episodes Section */
.all-episodes {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 60px 0;
}

.episodes-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.all-episodes h2 {
    font-size: 3rem;
    margin-bottom: 40px;
    font-weight: bold;
    text-align: center;
    color: var(--light-color);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.episodes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-bottom: 40px;
}

.episode-item {
    text-align: center;
    border-radius: 20px;
    border: 4px solid var(--light-color);
    overflow: hidden;
}

.episode-item img {
    width: 100%;
    height: 225px;
    object-fit: cover;
}

.episode-item h3 {
    font-size: 1.5rem;
    margin: 10px 0;
}

.episode-item p {
    font-size: 1rem;
    margin-bottom: 10px;
}

.view-all-btn {
    display: block;
    width: fit-content;
    font-weight: 700;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: var(--secondary-color);
    color: var(--light-color);
    border: 2px solid var(--light-color);
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.view-all-btn:hover {
    background-color: var(--light-color);
    font-weight: 700;
    color: var(--secondary-color);
    border: 2px solid var(--secondary-color);
}

/* Our Guests Section */
.our-guests {
    background-color: var(--primary-color);
    color: white;
    padding: 60px 0;
}

.guests-content {
    max-width: 1200px;
    margin: 0 auto;
    /* padding: 0 20px; */
}

.our-guests h2 {
    font-size: 3rem;
    /* margin-bottom: 40px; */
    font-weight: bold;
    text-align: center;
    color: var(--light-color);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.guests-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 40px;
}

.guest-item {
    flex: 1;
    text-align: center;
}

.guest-image {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    /* margin-bottom: 20px; */
    border: 4px solid var(--light-color);
}

.guest-item h3 {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.guest-item p {
    font-size: 1rem;
}


/* Contact Us styles */
.contact-us {
    /* padding: 60px 20px; */
    background-color: var(--primary-color);
    color: var(--light-color);
}

.contact-us h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 40px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.contact-content {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
}

.contact-image {
    flex: 1;
    margin-right: 20px;
    border-radius: 5px;
    background-color: var(--primary-color);
}

.contact-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.contact-us form {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}


.contact-us input,
.contact-us textarea {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    margin-bottom: 20px;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 1);
    border: solid 2px var(--secondary-color);
}

.contact-us textarea {
    height: 120px;
    resize: vertical;
}

.contact-us button {
    align-self: flex-end;
    padding: 10px 20px;
    width: fit-content;
    font-weight: 700;
    background-color: var(--secondary-color);
    color: var(--light-color);
    border: 2px solid var(--light-color);
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.contact-us button:hover {
    background-color: var(--light-color);
    font-weight: 700;
    color: var(--secondary-color);
    border: 2px solid var(--secondary-color);
}

/* Newsletter styles */

.newsletter {
    color: var(--secondary-color);
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.newsletter-content {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    padding: 60px;
    background-color: var(--light-color);
    border-radius: 20px;
}

.newsletter h2 {
    padding-top: 0;
    margin-top: 0;
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.newsletter p {
    font-size: 1rem;
    margin-bottom: 30px;
}

.newsletter-input{
    display: flex;
    justify-content: center;
    align-items: center;
    /* border-color: var(--secondary-color); */
    width: 100%;
    padding: 0.8rem 0.9rem;
    border-radius: 50px;
    border: 1px solid var(--secondary-color);
    height: 30px;
    font-size: 1rem;
    color: var(--secondary-color);
}

.newsletter input[type="email"] {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px 0 0 4px;
}

.newsletter button {
    padding: 12px 24px;
    background-color: var(--secondary-color);
    color: var(--light-color);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.newsletter button:hover {
    background-color: var(--light-color);
    color: var(--primary-color);
    border:1px solid var(--primary-color) ;
}



@media (max-width: 1024px) {
    .empowering-voice {
        padding: 50px;
    }

    .top-categories {
        padding: 50px;
    }

    .all-episodes {
        padding: 50px;
    }

    .our-guests {
        padding: 50px;
    }

    .guests-content {
        max-width: 600px;
    }

    .guest-image {
        width: 150px;
        height: 150px;
    }

    .contact-us {
        padding: 50px;
    }
}



/* Responsive design */
@media (max-width: 768px) {

    .podcast-hero {
        padding-top: 0;
    }


    /* Hero Section */

    .hero-content {
        flex-direction: column;
        max-width: 600px;
    }

    .hero-image,
    .hero-text {
        width: 70%;
    }

    .hero-image {
        height: fit-content;
        margin-bottom: 30px;
    }

    h1 {
        font-size: 2rem;
    }

    .subscribe-form {
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    .subscribe-form input,
    .subscribe-form button {
        width: 100%;
        border-radius: 5px;
    }

    /* Empowering Voice Section */

    .empowering-voice {
        padding: 30px;
    }

    .empowering-content {
        flex-direction: column-reverse;
    }

    .empowering-image {
        width: 60%;
    }

    .empowering-text {
        margin-top: 30px;
    }

    .empowering-text h2 {
        font-size: 2.5rem;
        text-align: center;
    }

    .empowering-text p {
        font-size: 1rem;
        text-align: center;
    }


    /* CATEGORIES SECTION */
    .top-categories h2 {
        font-size: 2.5rem;
    }

    .categories-list {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .category-item {
        width: 70%;
        gap: 10px;
        padding: 10px;
    }

    /* All Episodes Section */

    .all-episodes {
        padding: 0;
    }

    .episodes-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    .episode-item {
        margin-bottom: 10px;
        max-width: 400px;
    }

    .our-guests h2,
    .all-episodes h2 {
        font-size: 2.5rem;
    }

    .episodes-grid,
    .guests-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    /* Our Guests Section */

    .guests-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }


    .guest-item {
        max-width: 200px;
        padding: 10px;
    }

    .guest-image {
        width: 150px;
        height: 150px;
    }

    .contact-content {
        flex-direction: column;
    }

    .guest-item p {
        padding: 0;
        margin: 0;
    }

    .contact-image img {
        display: none;
    }

}

@media (max-width: 430px) {

    .podcast-page {
        padding: 80px 0 0 0;
    }

    h1 {
        font-size: 1.5rem;
    }

    p {
        font-size: 1rem;
    }

    .hero-text {
        width: 100%;
    }

    .hero-content {
        display: flex;
        flex-direction: column;
    }

    .empowering-voice {
        padding: 20px;
    }

    .empowering-content {
        padding: 0;
    }

    .empowering-image {
        width: 90%;
    }

    .empowering-text {
        width: 90%;
    }

    .empowering-text h2 {
        font-size: 2rem;
    }

    .empowering-text {
        font-size: 0.75rem;
    }

    .top-categories {
        padding: 20px;
    }

    .top-categories h2 {
        font-size: 1.5rem;
    }

    .categories-list {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .category-item {
        width: 90%;
    }

    .all-episodes {
        padding: 20px;
    }

    .all-episodes h2 {
        font-size: 2rem;
    }

    .episodes-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .episode-item {
        margin-bottom: 10px;
        max-width: 400px;
    }

    .our-guests h2,
    .all-episodes h2 {
        font-size: 2.5rem;
    }

    .episodes-grid,
    .guests-grid {
        grid-template-columns: 1fr;
    }

    .guests-content {
        gap: 0;
    }

    .our-guests h2 {
        font-size: 2rem;
    }

    .guest-item {
        max-width: 200px;
        padding: 0;
    }

    .guest-image {
        width: 150px;
        height: 150px;
    }
}






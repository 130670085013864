.privacy-policy-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: var(--primary-color);
    padding-top: 80px;
}

.privacy-container {
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background-color: var(--primary-color);
}

.privacy-container h1 {
    font-size: 2.5rem;
    color: var(--light-color);
    margin-bottom: 10px;
}

.privacy-container h2 {
    font-size: 1.75rem;
    color: var(--light-color);
    margin-top: 20px;
}

.privacy-container p {
    font-size: 1rem;
    line-height: 1.6;
    color: var(--light-color);
    margin-bottom: 20px;
}

.privacy-container ul {
    margin-left: 20px;
}

.privacy-container ul li {
    list-style: disc;
    margin-bottom: 10px;
    color: var(--light-color);
}

.last-updated {
    font-style: italic;
    color: var(--light-color);
    margin-bottom: 20px;
}

/* Responsive */
@media (max-width: 1024px) {
    .privacy-container {
        padding: 0 50px;
    }
}

@media (max-width: 768px) {
    .privacy-container {
        padding: 0 50px;
    }

    .privacy-container h1 {
        font-size: 2rem;
    }

    .privacy-container h2 {
        font-size: 1.5rem;
    }
}

@media (max-width: 430px) {
    .privacy-container {
        padding: 0 50px;
    }

    .privacy-container h1 {
        font-size: 1.5rem;
    }

    .privacy-container h2 {
        font-size: 1.25rem;
    }
}
.bhojanam-page {
    color: white;
    background-color: #1995AD;
}


/* Navbar styles */

.b-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 150px;
    background-color: var(--primary-color);
    color: var(--light-color);
    position: relative;
}

.b-navbar-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.b-navbar-logo {
    font-size: 2em;
    font-weight: bold;
    color: var(--light-color);
    text-decoration: none;
    transition: transform 0.3s ease, cursor 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 6px;
}

.b-navbar-logo:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.b-logo-text {
    font-size: 1.2em;
    line-height: 1;
}

.b-logo-subtext {
    font-size: 0.6em;
    letter-spacing: 0.1em;
}

.b-orange-u {
    color: #FF671F;
}

.b-navbar-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2.5rem;
    font-weight: bold;
}

.b-nav-links {
    display: flex;
    gap: 20px;
    align-items: center;
}

.b-nav-links a {
    color: var(--light-color);
    text-decoration: none;
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.b-nav-links a:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
}

/* SCROLLABLE CODE FOR NAVBAR FOR ALL 5 PAGES OTHER THAN LANDING PAGE */

.b-navbar-scrolled {
    padding: 20px 150px;
    background-color: var(--light-color);
    /* color: #1A21FF; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.b-navbar-scrolled .b-navbar-logo {
    color: var(--primary-color);
}

.b-navbar-scrolled .b-logo-subtext {
    color: var(--dark-color);
}

.b-navbar-scrolled .b-navbar-center {
    color: var(--primary-color);
}

.b-navbar-scrolled .b-nav-links a {
    color: var(--primary-color);
}



/* Transition for smooth scrolling effect */
.b-navbar,
.b-navbar-scrolled,
.b-navbar-logo {
    transition: all 0.3s ease;
}

.project-description {
    font-size: 1.5rem;
    line-height: 1.8;
    margin-bottom: 30px;
    padding: 0 20px;
    padding-top: 140px;
    text-align: justify;
}

.project-phases,
.project-timeline {
    width: 100%;
    max-width: 1000px;
    display: block;
    margin: 2rem auto;
}

.phase-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.phase {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    gap: 20px;
}

.phase-description {
    width: 40%;
    margin: 50px;
}

.phase-description h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.phase-description ul {
    list-style: square;
    font-size: 1.25rem;
    line-height: 1.8;
    padding-left: 20px;
}

.phase-img {
    width: 50%;
}

.phase-img img {
    width: 100%;
    border-radius: 10px;
}

/* Footer styling */
.b-footer {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 5px 10%;
    border-top: 1px solid var(--light-color);
}

.b-footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.b-social-icons {
    display: flex;
    gap: 20px;
}

.b-social-icons a {
    color: white;
    transition: opacity 0.3s ease;
}

.b-social-icons a:hover {
    opacity: 0.7;
}

/* Keywords Section Styles */
.keywords-section {
    background-color: #1995AD;
    padding: 2rem 4rem;
    text-align: center;
}

.keywords-title {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
}

.keywords-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.75rem;
}

.keyword-tag {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.875rem;
    transition: background-color 0.3s ease;
}

.keyword-tag:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .keywords-section {
        padding: 1.5rem 1rem;
    }

    .keywords-title {
        font-size: 1.25rem;
    }

    .keyword-tag {
        font-size: 0.75rem;
        padding: 0.4rem 0.8rem;
    }
}

/* Responsive Styles */

/* Tablets and below */
@media (max-width: 1024px) {
    .project-title {
        font-size: 2rem;
    }

    .project-description {
        font-size: 1.25rem;
    }

    .phase {
        flex-direction: column;
    }

    .phase-description,
    .phase-img {
        width: 100%;
        margin: 20px 0;
    }
}



@media (max-width: 1080px) {

    .b-navbar,
    .b-navbar-scrolled {
        padding: 15px 30px;
    }

    .b-navbar-scrolled {
        padding: 10px 30px;
    }

    .b-navbar-center {
        position: absolute;
        left: 50%;
        transform: translateX(50%);
        font-size: 1.5rem;
        font-weight: bold;
    }

    .b-hero-section {
        padding-top: 140px;
    }

    .b-nav-links {
        display: none;
    }
}

/* Mobile view */
@media (max-width: 768px) {
    .project-title {
        font-size: 1.75rem;
        padding-top: 20px;
    }

    .project-description {
        font-size: 1.1rem;
    }

    .phase-description h2 {
        font-size: 1.5rem;
    }

    .phase-description ul {
        font-size: 1rem;
    }

    .p-footer-content {
        flex-direction: column;
        text-align: center;
    }

    .p-social-icons {
        justify-content: center;
        gap: 10px;
        margin-top: 10px;
    }
}
.ventures-page {
    background-color: var(--light-color);
    min-height: 100vh;
    padding-top: 80px;
}

.ventures-content {
    /* background-image: url(''); */
    padding: 60px 15% 100px;
    max-width: 1200px;
    margin: 0 auto;
}

.ventures-title {
    font-size: 3rem;
    color: var(--secondary-color);
    margin-bottom: 1rem;
}

.ventures-description {
    font-size: 1.1rem;
    color: var(--secondary-color);
    line-height: 1.6;
    margin-bottom: 3rem;
}

.company-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem;
}

.company-card {
    background-color: cover;
    /* border: 1px solid var(--secondary-color); */
    border-radius: 4px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
    color: var(--light-color);
}


.company-card:hover {
    box-shadow: 0 5px 10px #ddd;
    transform: scale(1.01);
}

.company-name {
    font-size: 2rem;
    font-weight: bolder;
    color: var(--light-color);
    /* background-color: rgba(255, 255, 255, 0.3); */
    padding: 0;
    border-radius: 4px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

/* Vision Section */
.vision-section {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 80px 10%;
    text-align: center;
}

.vision-title {
    margin-top: 0;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

.vision-description {
    font-size: 1.3rem;
    line-height: 1.6;
    max-width: 900px;
    margin: 0 auto;
}

/* Explore Section */
.explore-section {
    background-color: var(--light-color);
    padding: 60px 10%;
    text-align: center;
}

.explore-title {
    font-size: 1.8rem;
    color: var(--secondary-color);
    margin-bottom: 1rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.ventures-list {
    list-style-type: none;
    padding-bottom: 20px;
    margin: 0;
    font-size: 1.2rem;
    color: var(--secondary-color);
}

.ventures-list li {
    margin-bottom: 5px;
}

.explore-button {
    background-color: var(--primary-color);
    color: var(--light-color);
    border: 2px solid var(--primary-color);
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.explore-button:hover {
    background-color: var(--light-color);
    color: var(--primary-color);
    border-color: var(--primary-color);
}

@media (max-width: 1024px) {
    .ventures-page {
        padding-top: 70px;
    }
}



@media (max-width: 768px) {
    .ventures-content {
        padding: 100px 5% 30px;
    }

    .ventures-title {
        font-size: 2.5rem;
    }

    .company-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    /* Vision and Explore Section */

    .vision-section,
    .explore-section {
        padding: 40px 5%;
    }

    .vision-title {
        font-size: 2rem;
    }

    .vision-description,
    .explore-title {
        font-size: 1rem;
    }

    .explore-title {
        font-size: 1.5rem;
    }

    .explore-button {
        padding: 10px 20px;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .vision-title {
        font-size: 1.8rem;
    }

    .explore-title {
        font-size: 1.3rem;
    }
}
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background-color: var(--light-color);
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: var(--secondary-color);
}

.popup h2 {
    color: var(--primary-color);
    margin-bottom: 10px;
}

.popup p {
    margin-bottom: 20px;
    color:var(--secondary-color);
}

.popup form {
    display: flex;
    flex-direction: column;
}

.popup input,
.popup textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.popup .submit-btn {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.subscribe-label {
    color: var(--primary-color) ;
}

.popup .submit-btn:hover {
    background-color: var(--secondary-color);
}

@media (max-width: 600px) {

    .popup-overlay {
        padding: 20px;
    }
    .popup {
        width: 95%;
        padding: 15px;
    }
}
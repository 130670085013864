.dropdown-menu {
    display: flex;
    flex-direction: column;
    background-color: var(--light-color);
    color: var(--primary-color);
    padding: 20px;
    border-radius: 5px;
}

.dropdown-menu a {
    text-decoration: none;
    color: var(--primary-color);
    font-family: sans-serif;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
    display: block;

}

.dropdown-menu a:hover {
    text-decoration: underline;
    text-underline-offset: 5px; 
}

@media (max-width: 1024px) {
    .dropdown-menu {
        background-color: transparent;
        padding: 0;
    }

    .dropdown-menu a {
        color: var(--light-color);
        font-size: 16px;
        
    }

    .navbar-scrolled .dropdown-menu {
        background-color: var(--light-color);
    }

    .navbar-scrolled .dropdown-menu a {
        color: var(--primary-color);
    }
}
.body {
    overflow: hidden;
    padding-top: 80px;
}

/* Navbar styles */

.p-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 150px;
    background-color: var(--primary-color);
    color: var(--light-color);
    position: relative;
}

.p-navbar-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.p-navbar-logo {
    font-size: 2em;
    font-weight: bold;
    color: var(--light-color);
    text-decoration: none;
    transition: transform 0.3s ease, cursor 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 6px;
}

.p-navbar-logo:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.p-logo-text {
    font-size: 1.2em;
    line-height: 1;
}

.p-logo-subtext {
    font-size: 0.6em;
    letter-spacing: 0.1em;
}

.p-orange-u {
    color: #FF671F;
}

.p-navbar-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2.25rem;
    font-weight: bold;
}



/* SCROLLABLE CODE FOR NAVBAR FOR ALL 5 PAGES OTHER THAN LANDING PAGE */

.p-navbar-scrolled {
    padding: 20px 150px;
    background-color: var(--light-color);
    /* color: #1A21FF; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.p-navbar-scrolled .p-navbar-logo {
    color: var(--primary-color);
}

.p-navbar-scrolled .p-logo-subtext {
    color: var(--primary-color);
}

.p-navbar-scrolled .p-navbar-center {
    color: var(--primary-color);
}



/* Transition for smooth scrolling effect */
.p-navbar,
.p-navbar-scrolled,
.p-navbar-logo {
    transition: all 0.3s ease;
}


/* Hero section styles */


.p-hero-section {
    display: flex;
    padding: 1rem 0 3.5rem 4rem;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary-color);
    overflow: hidden;
    padding-top: 140px;
}

.p-hero-content {
    flex: 1;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.p-hero-content h1 {
    margin-top: 0;
    font-size: 2.5rem;
    margin-bottom: 0;
    color: var(--light-color);
    line-height: 1.15;
}

.p-hero-content p {
    font-size: 1.25rem;
    margin-top: 0.25rem;
    margin-bottom: 2rem;
    color: var(--light-color);
}

.p-hero-video-container {
    width: 100%;
    max-width: 650px;
    height: 350px;
    margin-bottom: 2rem;
    border: 2px solid white;
    /* Add white border */
    border-radius: 8px;
    /* Maintain border radius */
}

.p-hero-video-container iframe {
    width: 100%;
    height: 100%;
    border-radius: 6px;
}

.p-get-started-btn {
    background-color: var(--primary-color);
    color: var(--light-color);
    border: 2px solid var(--light-color);
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 500;
}

.p-get-started-btn:hover {
    font-weight: 600;
    background-color: var(--light-color);
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

.p-hero-images {
    flex: 1;
    position: relative;
    height: 500px;
}

.p-desktop-image {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 80%;
    z-index: 1;
}

.p-dashboard-image {
    position: absolute;
    right: -16.5%;
    top: 2%;
    width: 100%;
    height: 105%;
    z-index: 2;
}

.p-phone-image {
    position: absolute;
    left: 5%;
    top: 18%;
    width: 30%;
    z-index: 3;
}


/* Features section */

.p-featurecards-section {
    padding: 2rem 6rem;
    text-align: center;
    background-color: var(--primary-color);
}

.p-featurecards-section h2 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
    color: var(--light-color);
}

.p-featurecards-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.p-featurecards-box {
    background-color: var(--light-color);
    color: var(--primary-color);
    padding: 1.5rem;
    /* border-radius: 8px; */
    font-weight: bold;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Video section */

.p-video-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 2rem;
    background-color: var(--primary-color);
    color: var(--light-color);
}

.p-video-content {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.p-video-content h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-align: center;
}

.p-video-content p {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    text-align: center;
}

.p-video-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
}

.p-primary-action {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border: 2px solid var(--light-color);
    cursor: pointer;
    background-color: var(--primary-color);
    color: var(--light-color);
    width: fit-content;
    align-items: center;
}

.p-primary-action:hover {
    background-color: var(--light-color);
    color: var(--primary-color);
}

.p-video-player img {
    width: 100%;
    border-radius: 8px;
}


.p-video-player {
    position: relative;
    width: 100%;
    max-width: 700px;
    padding-bottom: 28.125%;
    /* 16:9 aspect ratio, but half the height (56.25% / 2) */
    height: 0;
    overflow: hidden;
}

.p-video-player iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Features details section */

.p-features-details-section {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 2rem 2rem;
    text-align: center;
}

.p-features-details-section h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.p-features-details-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    max-width: 900px;
    margin: 0 auto;
}

.p-feature-detail {
    padding: 2rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.p-feature-detail h3 {
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.p-feature-detail p {
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;
}

/* Review section */

.p-review-section {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 4rem 2rem;
}

.p-review-container {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
}

.p-review-nav-button {
    background: none;
    border: none;
    color: var(--light-color);
    cursor: pointer;
    padding: 1rem;
}

.p-review-content {
    text-align: center;
    padding: 0 2rem;
    flex-grow: 1;
}

.p-review-icon {
    width: 80px;
    height: 80px;
    background-color: var(--light-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1rem;
}

.p-review-text {
    font-size: 1.25rem;
    line-height: 1.6;
    margin-bottom: 1.5rem;
}

.p-review-name {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.p-review-position {
    font-size: 1rem;
    opacity: 0.8;
}

/* FAQ Section */
.p-faq-section {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 2rem 2rem;
}

.p-faq-section h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.p-faq-container {
    max-width: 800px;
    margin: 0 auto;
}

.p-faq-item {
    background-color: var(--light-color);
    color: var(--primary-color);
    margin-bottom: 1rem;
    border-radius: 4px;
    overflow: hidden;
}

.p-faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.p-faq-answer {
    padding: 1.5rem;
    background-color: var(--primary-color);
    line-height: 1.6;
    color: var(--light-color);
    border: 2px solid var(--light-color);
}

/* App Download Section */
.p-app-download-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10% 0 10%;
    background-color: var(--primary-color);
    position: relative;
    overflow: hidden;
}

.p-app-download-content {
    flex: 1;
    max-width: 50%;
}

.p-app-download-content h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: var(--light-color);
}

.p-app-download-content p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: var(--light-color);
}

.p-app-store-buttons {
    display: flex;
    gap: 20px;
}


.p-app-screenshot {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 10%;
    top: 50%;
    transform: translateY(20%);
}

.p-app-screenshot img {
    width: 60%;
    height: auto;
}

.p-button-container {
    display: flex;
    gap: 16px;
}

.p-app-store-button,
.p-google-play-button {
    display: flex;
    align-items: center;
    background-color: var(--primary-color);
    color: var(--light-color);
    border-radius: 7px;
    padding: 15px 16px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    border: 1px solid var(--light-color);
}

.p-google-play-button {
    border-radius: 8px;
}

.p-store-icon {
    margin-right: 8px;
    font-size: 24px;
}

/* Footer */
.p-footer {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 5px 10%;
    border-top: 1px solid var(--light-color);
}

.p-footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.p-social-icons {
    display: flex;
    gap: 20px;
}

.p-social-icons a {
    color: var(--light-color);
    transition: opacity 0.3s ease;
}

.p-social-icons a:hover {
    opacity: 0.7;
}

.p-launch-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: var(--light-color);
    color: var(--primary-color);
    border: none;
    padding: 12px 20px;
    border-radius: 30px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    z-index: 1000;
}

.p-launch-button:hover {
    background-color: var(--primary-color);
    color: var(--light-color);
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}


/* Keywords Section */
.p-keywords-section {
    background-color: var(--primary-color);
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.p-keywords-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    max-width: 1200px;
    width: 100%;
}

.p-keyword-tag {
    background-color: var(--light-color);
    color: var(--primary-color);
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: capitalize;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.p-keyword-tag:hover {
    background-color: rgba(255, 255, 255, 0.9);
    transform: scale(1.05);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .p-keywords-section {
        padding: 1rem;
    }

    .p-keyword-tag {
        font-size: 0.8rem;
        padding: 0.4rem 0.8rem;
    }
}

@media (max-width: 1024px) {
    .p-hero-content {
        max-width: 33%;
        min-width: 30%;
    }

    .p-hero-content h1 {
        font-size: 2rem;
    }

    .p-hero-content p {
        font-size: 1rem;
    }

    .p-hero-images {
        height: 400px;
    }

    .p-desktop-image {
        height: 400px;
    }

    .p-phone-image {
        height: 350px;
        min-width: 23%;
    }

    .p-app-download-section {
        padding: 20px;
    }
}

@media (max-width: 1080px) {

    .p-navbar,
    .p-navbar-scrolled {
        padding: 15px 30px;
    }

    .p-navbar-scrolled {
        padding: 10px 30px;
    }

    .p-navbar-center {
        position: absolute;
        left: 50%;
        transform: translateX(50%);
        font-size: 1.5rem;
        font-weight: bold;
    }

    .p-hero-section {
        padding-top: 140px;
    }
}

@media (max-width: 768px) {
    .p-hero-section {
        padding: 0;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "hero-content"
            "hero-images";
        align-items: center;
        padding-top: 100px;
    }

    .p-hero-content {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 2rem;
        text-align: center;
    }

    .p-hero-video-container {
        max-width: 100%;
        height: 240px;
        border: 2px solid white;
        /* Add white border */
    }

    .p-hero-video-container iframe {
        height: 100%;
        border-radius: 6px;
        /* Slightly reduce inner iframe border radius */
    }

    .p-hero-images {
        grid-area: hero-images;
        height: 400px;
    }

    .p-featurecards-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .p-app-download-section {
        padding: 20px;
    }

    .p-app-download-content h2 {
        font-size: 1.5rem;
    }

    .p-app-download-content p {
        font-size: 1rem;
    }

    .p-video-section {
        padding: 0;
    }

    .p-video-content {
        width: 70%;
    }

    .p-video-player {
        width: 70%
    }

    .p-features-details-section {
        padding: 1rem;
    }

    .p-features-details-grid {
        gap: 1rem;
        min-width: 400px;
    }

    .p-feature-detail {
        padding: 1rem;
    }
}

@media (max-width: 430px) {
    .p-hero-images {
        height: 300px;
        overflow: hidden;
    }

    .p-desktop-image {
        height: 300px;
    }

    .p-dashboard-image {
        overflow: hidden;
    }

    .p-featurecards-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .p-featurecards-section {
        padding: 1rem;
    }

    .p-video-content h2 {
        font-size: 2rem;
    }

    .p-video-section {
        padding: 0;
    }

    .p-video-content {
        width: 70% !important;
    }

    .p-features-details-section {
        padding: 0;
        margin: 0;
        overflow: hidden;
    }

    .p-features-details-grid {
        gap: 0rem;
        min-width: 300px;
    }

    .p-feature-detail {
        padding: 0.5rem;
    }

    .p-features-details-section h2 {
        font-size: 1.5rem;
        margin-bottom: 0rem;
    }

    .p-feature-detail h3 {
        font-size: 1rem;
    }

    .p-feature-detail p {
        font-size: 0.75rem;
    }

    .p-review-section {
        padding: 0;
    }

    .p-review-text {
        font-size: 1rem;
    }

    .p-faq-section h2 {
        font-size: 2rem;
    }

    .p-app-download-section {
        padding: 10px;
    }

    .p-button-container {
        flex-direction: column;
    }

    .p-app-store-button,
    .p-google-play-button {
        width: auto;
        padding: 8px 12px;
        font-size: 10px;
    }

    .p-store-icon {
        font-size: 20px;
    }
}


/* Responsive styles */
@media (min-width: 430px) {
    .p-button-container {
        flex-direction: row;
    }

    .p-app-store-button,
    .p-google-play-button {
        width: auto;
        padding: 8px 12px;
        font-size: 12px;
    }

    .p-store-icon {
        font-size: 20px;
    }
}

@media (min-width: 768px) {
    .p-button-container {
        gap: 20px;
    }

    .p-app-store-button,
    .p-google-play-button {
        padding: 8px 15px;
        font-size: 12px;
    }

    .p-store-icon {
        font-size: 20px;
    }
}

@media (min-width: 1024px) {

    .p-app-store-button,
    .p-google-play-button {
        padding: 14px 28px;
        font-size: 15px;
    }

    .p-store-icon {
        font-size: 25px;
    }
}





.footer {
    color: var(--light-color);
    padding: 60px 100px 60px 100px;
}

.landing-footer {
    background-color: var(--primary-color);
}

.default-footer {
    background-color: var(--secondary-color);
}

.footer-content {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.footer-logo {
    font-size: 24px;
    font-weight: bold;
    color: var(--light-color);
    text-decoration: none;
    margin-bottom: 20px;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 1.25em;
    border-bottom: 1px solid var(--light-color);
    padding-bottom: 20px;
}

.footer-column a {
    display: block;
    color: var(--light-color);
    text-decoration: none;
    margin-bottom: 10px;
}

.newsletter-signup p {
    margin-bottom: 20px;
    font-size: 1.25em;
    color: var(--light-color);
}

.email-input {
    display: flex;
    border-bottom: 1px solid var(--light-color);
    margin-bottom: 40px;
}

.email-input input {
    flex-grow: 1;
    background: transparent;
    border: none;
    color: var(--light-color);
    padding: 10px 0;
    font-size: 2em;
}

.email-input input::placeholder {
    color: var(--light-color);
}

.email-input button {
    background: transparent;
    border: none;
    color: var(--light-color);
    cursor: pointer;
}

.email-input button svg {
    width: 24px;
    height: 24px;
    border: 2px solid var(--light-color);
    border-radius: 50%;
}

.social-links p {
    font-size: 1.25em;
    margin-bottom: 10px;
    font-weight: bold;
    color: var(--light-color);
}

.social-icons a {
    color: var(--light-color);
    font-size: 24px;
    margin-right: 20px;
    text-decoration: none;
}

.back-to-top {
    font-size: 1.25em;
    text-align: right;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-to-top a {
    color: var(--light-color);
    text-decoration: none;
}

.back-to-top button {
    background: transparent;
    border: none;
    color: var(--light-color);
    cursor: pointer;
    rotate: -90deg;
}

.back-to-top button svg {
    width: 20px;
    height: 20px;
    border: 2px solid var(--light-color);
    border-radius: 50%;
}


.footer-bottom {
    font-size: 1.25em;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--light-color);
    padding-top: 20px;
}

.footer-bottom a {
    color: var(--light-color);
    text-decoration: none;
    margin-left: 20px;
}

.footer-bottom p {
    margin: 0;
    color: var(--light-color);
    font-size: 1.25rem;
}


@media (max-width: 768px) {
    .footer {
        color: var(--light-color);
        padding: 40px 50px 35px 50px;
    }

    .email-input {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .email-input input {
        font-size: 1.5em;
        padding: 8px 0;
        width: 100%;
    }

    .email-input button {
        margin-top: 10px;
    }

    .email-input button svg {
        width: 20px;
        height: 20px;
    }

    .footer-links {
        display: flex;
    }

    .footer-column {
        margin-bottom: 20px;
    }

    .footer-bottom {
        font-size: 1em;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
    }

    .footer-bottom a {
        margin: 10px 0;
    }
}

@media (max-width: 480px) {

    .email-input {
        width: 100%;
    }

    .email-input input {
        font-size: 1.2em;
    }

    .email-input button {

        padding: 10px 0;
        border: none;
        text-align: center;
    }

    .email-input button svg {
        width: 18px;
        height: 18px;
    }

    .footer-logo {
        text-align: center;
    }

    .footer-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-items: center;
        gap: 50px;
    }

    .footer-links {
        font-size: 1em;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin: 0;
        gap: 0;
    }

    .social-icons{
        display: flex;
        justify-content: space-between;
    }

    .footer-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1em;
        text-align: center;
        gap: 10px;
    }

    .footer-bottom a {
        margin: 2px 0;
        display: block;
    }
}
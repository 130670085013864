.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 150px;
    background-color: var(--primary-color);
    color: var(--light-color);
    position: relative;
}

.navbar-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.navbar-logo {
    font-size: 2em;
    font-weight: bold;
    color: var(--light-color);
    text-decoration: none;
    transition: transform 0.3s ease, cursor 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 6px;
}

.navbar-logo:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.logo-text {
    font-size: 1.2em;
    line-height: 1;
}

.logo-subtext {
    font-size: 0.6em;
    letter-spacing: 0.1em;
}

.orange-u {
    color: #FF671F;
}

.navbar-links {
    display: flex;
    gap: 20px;
    align-items: center;
}

.navbar-links a {
    color: var(--light-color);
    text-decoration: none;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.navbar-links a:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
}

.get-in-touch {
    border: 1px solid var(--light-color);
    padding: 20px 15px;
    border-radius: 10px;
    text-decoration: none;
    color: var(--light-color);
    background-color: transparent;
    transition: all 0.3s ease;
}

.get-in-touch:hover {
    color: var(--primary-color);
    background-color: var(--light-color);
    border: 1px solid var(--light-color);
    text-decoration: none !important;
}

.dropdown {
    position: relative;
    font-size: 18px;
    font-weight: 700;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: var(--light-color);
    color: var(--primary-color);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transform: translateY(10px);
}

.dropdown-toggle {
    background: none;
    border: none;
    color: var(--light-color);
    cursor: pointer;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
}

.dropdown-toggle:hover {
    color: var(--light-color);
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: var(--light-color);
}

.dropdown-arrow {
    display: inline-block;
    margin-left: 4px;
    text-decoration: none !important;
}

.dropdown-toggle:hover .dropdown-arrow {
    text-decoration: none;
}

.mobile-menu-toggle {
    display: none;
    background: none;
    border: none;
    color: var(--light-color);
    font-size: 2em;
    cursor: pointer;
}

.desktop-only {
    display: block;
}

.mobile-only {
    display: none;
}

@media (max-width: 1080px) {
    .navbar {
        padding: 30px;
    }

    .navbar-logo {
        font-size: 1.5em;
    }

    .navbar-links {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: var(--secondary-color);
        padding: 20px;
        z-index: 1000;
    }

    .navbar-links.mobile-open {
        display: flex;
    }

    .mobile-menu-toggle {
        display: block;
    }

    .desktop-only {
        display: none;
    }

    .mobile-only {
        display: block;
        width: 100%;
    }

    .navbar-links a,
    .navbar-links .mobile-only .dropdown-menu a,
    .navbar-links .mobile-only .more-header {
        padding: 5px 0;
        width: 100%;
        text-align: left;
        color: var(--light-color);
        font-size: 20px;
        font-weight: bold;
    }

    .more-header {
        margin: 0;
        padding-top: 20px !important;
    }

    .get-in-touch {
        margin-top: 10px;
        border: none;
    }

    .get-in-touch:hover {
        padding-left: 20px;
        color: var(--secondary-color);
    }

    .mobile-only .dropdown-menu {
        position: static;
        background-color: transparent;
        padding: 0;
    }

}


/* SCROLLABLE CODE FOR NAVBAR FOR ALL 5 PAGES OTHER THAN LANDING PAGE */

.navbar-scrolled {
    padding: 20px 150px;
    background-color: var(--light-color);
    /* color: #1A21FF; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-scrolled .navbar-logo {
    color: var(--primary-color);
}

.navbar-scrolled .logo-subtext {
    color: var(--dark-color);
}


.navbar-scrolled .navbar-links a,
.navbar-scrolled .dropdown-toggle {
    color: var(--secondary-color);
}

.navbar-scrolled .get-in-touch {
    color: var(--primary-color);
    border-color: var(--secondary-color);
}

.navbar-scrolled .get-in-touch:hover {
    color: var(--light-color);
    background-color: var(--secondary-color);
}

.navbar-scrolled .dropdown-toggle:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: var(--secondary-color);
}


@media (max-width: 1080px) {

    .navbar,
    .navbar-scrolled {
        padding: 15px 30px;
    }

    .navbar-scrolled {
        padding: 10px 30px;
    }

    .navbar-scrolled .mobile-menu-toggle {
        color: var(--secondary-color);
    }

    .navbar-scrolled .navbar-links.mobile-open {
        background-color: var(--light-color);
    }

    .navbar-scrolled .navbar-links a,
    .navbar-scrolled .navbar-links .mobile-only .dropdown-menu a,
    .navbar-scrolled .navbar-links .mobile-only .more-header {
        color: var(--secondary-color);
    }

    .navbar-scrolled .get-in-touch {
        color: var(--secondary-color);
    }

    .navbar-scrolled .get-in-touch:hover {
        color: var(--light-color);
        background-color: #0E0066;
    }
}


/* Transition for smooth scrolling effect */
.navbar,
.navbar-scrolled,
.navbar-logo,
.navbar-links a,
.dropdown-toggle,
.get-in-touch {
    transition: all 0.3s ease;
}
:root {
    /* Primary Colors */
    --primary-color: #1995AD;
    /* Blue */
    --secondary-color: #1995AD;
    /* Dark Blue */
    --light-color:white;
    /* White */
    --dark-color:#333;
    /* Black */
}
/* CHAKRAStyles.css */

.c-container {
    font-family: Arial, sans-serif;
    color: var(--light-color);
    background-color: var(--primary-color);
}

/* Navbar styles */

.c-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 150px;
    background-color: var(--primary-color);
    color: var(--light-color);
    position: relative;
}

.c-navbar-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.c-navbar-logo {
    font-size: 2em;
    font-weight: bold;
    color: var(--light-color);
    text-decoration: none;
    transition: transform 0.3s ease, cursor 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 6px;
}

.c-navbar-logo:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.c-logo-text {
    font-size: 1.2em;
    line-height: 1;
}

.c-logo-subtext {
    font-size: 0.6em;
    letter-spacing: 0.1em;
}

.c-orange-u {
    color: #FF671F;
}

.c-navbar-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2.5rem;
    font-weight: bold;
}

.c-nav-links {
    display: flex;
    gap: 20px;
    align-items: center;
}

.c-nav-links a {
    color: var(--light-color);
    text-decoration: none;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.c-nav-links a:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
}

/* SCROLLABLE CODE FOR NAVBAR FOR ALL 5 PAGES OTHER THAN LANDING PAGE */

.c-navbar-scrolled {
    padding: 20px 150px;
    background-color: var(--light-color);
    /* color: #1A21FF; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.c-navbar-scrolled .c-navbar-logo {
    color: var(--primary-color);
}

.c-navbar-scrolled .c-logo-subtext {
    color: var(--dark-color);
}

.c-navbar-scrolled .c-navbar-center {
    color: var(--primary-color);
}

.c-navbar-scrolled .c-nav-links a{
    color: var(--primary-color);
}



/* Transition for smooth scrolling effect */
.c-navbar,
.c-navbar-scrolled,
.c-navbar-logo {
    transition: all 0.3s ease;
}

/* HERO SECTION */
/* HERO SECTION */

.c-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 1000px;
    margin: 50px auto;
    padding-top: 140px;
}

.c-hero h1 {
    font-size: 3.5em;
    margin-bottom: 20px;
    line-height: 1;
    font-weight: bolder;
}

.c-hero p {
    font-size: 1.5em;
    margin-bottom: 40px;
    line-height: 1.5;
    max-width: 800px;
}

.c-hero button {
    background-color: var(--light-color);
    color: var(--primary-color);
    border: none;
    padding: 1rem 1.5rem;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
}

.c-hero button:hover {
    background-color: var(--light-color);
}


/* About Section */
.c-about-section {
    padding: 80px 20px;
    /* background-color: rgba(0, 0, 0, 0.1); */
}

.c-about-container {
    max-width: 1000px;
    margin: 0 auto;
}

.c-about-header {
    text-align: center;
    margin-bottom: 60px;
}

.c-about-header h2 {
    font-size: 3.5em;
    margin-bottom: 40px;
}

.c-about-header p {
    max-width: 1200px;
    margin: 0 auto;
    line-height: 1.8;
}

.c-about-header p {
    margin-bottom: 20px;
    font-size: 1.35em;
}


/* Mission and Vision */
.c-mission-vision {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    padding-bottom: 20px;
}

.c-mission,
.c-vision {
    background-color: var(--light-color);
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    color: var(--primary-color);
}

.c-mission h3,
.c-vision h3 {
    font-size: 2em;
    margin-bottom: 20px;
    color: var(--primary-color);
}


/* Core Values */
.c-core-values {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 1000px;
    /* padding: auto; */
}

.c-core-values h3 {
    font-size: 2.5em;
    margin-bottom: 40px;
    color: var(--light-color);
}

.c-values-quadrant {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 2px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: var(--light-color);
    padding: 2px;
    /* aspect-ratio: 1; */
}

.c-value-item {
    background-color: var(--primary-color);
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.c-value-item h4 {
    font-size: 1.5em;
    margin-bottom: 15px;
    color: var(--light-color);
}

.c-value-item p {
    font-size: 1em;
    line-height: 1.5;
    max-width: 250px;
}

/* Projects Section */

.c-projects-section {
    padding: 0 20px;
}

.c-projects-container {
    max-width: 1200px;
    margin: 0 auto;
    max-width: 1200px;
}

.c-projects-header {
    margin: 0;
    text-align: center;
}

.c-projects-header h2 {
    margin: 0;
    font-size: 3em;
    color: var(--light-color);
}

.c-project-content {
    border-radius: 15px;
}

.c-project-intro {
    text-align: center;
    margin-bottom: 40px;
}

.c-project-intro h3 {
    font-size: 2em;
    color: var(--light-color);
    margin-bottom: 20px;
}

.c-project-tagline {
    font-size: 1.2em;
    max-width: 800px;
    margin: 0 auto;
    color: var(--light-color);
    line-height: 1.4;
}

.c-project-overview {
    max-width: 1000px;
    margin: 0 auto 50px;
    text-align: center;
    line-height: 1.8;
    font-size: 1.2em;
}

.c-project-overview p {
    font-size: 1em;
}


/* Features Grid */
.c-features-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin-bottom: 60px;
}

.c-feature-column {
    padding: 30px;
    background-color: var(--light-color);
    border-radius: 10px;
    text-align: center;
}

.c-feature-column h4 {
    color: var(--primary-color);
    font-size: 1.4em;
    margin-top: 5px;
    /* margin-bottom: 20px; */
}

.c-feature-column ul {
    list-style-type: none;
    padding: 0;
}

.c-feature-column li {
    margin-bottom: 12px;
    /* padding-left: 10px; */
    position: relative;
    color: var(--primary-color);
}

.c-feature-column li:before {
    /* content: "•"; */
    position: absolute;
    left: 0;
    color: var(--primary-color);
}


/* Capabilities Section */
.c-capabilities-section {
    margin-bottom: 60px;
}

.c-capabilities-section h4 {
    color: var(--light-color);
    font-size: 1.8em;
    text-align: center;
    margin-bottom: 30px;
}

.c-capabilities-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    text-align: center;
}

.c-capability-card {
    padding: 25px;
    background-color: var(--light-color);
    border-radius: 10px;
}

.c-capability-card h5 {
    color: var(--primary-color);
    font-size: 1.2em;
    margin-top: 10px;
    /* margin-bottom: 15px; */
}

.c-capability-card ul {
    list-style-type: none;
    padding: 0;
}

.c-capability-card li {
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
    color: var(--primary-color);
}

.c-capability-card li:before {
    content: "→";
    position: absolute;
    left: 0;
    color: var(--primary-color);
}


/* Impact Section */
.c-impact-section {
    margin-bottom: 60px;
}

.c-impact-section h4 {
    color: var(--light-color);
    font-size: 1.8em;
    text-align: center;
    margin-bottom: 30px;
}

.c-impact-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.c-impact-card {
    padding: 25px;
    background-color: var(--light-color);
    border-radius: 10px;
    text-align: center;
}

.c-impact-card h5 {
    color: var(--primary-color);
    font-size: 1.2em;
    margin-top: 10px;
    /* margin-bottom: 15px; */
}

.c-impact-card ul {
    list-style-type: none;
    padding: 0;
}

.c-impact-card li {
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
    color: var(--primary-color);
}

.c-impact-card li:before {
    content: "✓";
    position: absolute;
    left: 0;
    color: var(--primary-color);
}


/* Workflow Section */
.c-workflow-section {
    margin-bottom: 60px;
}

.c-workflow-section h4 {
    color: var(--light-color);
    font-size: 1.8em;
    text-align: center;
    margin-bottom: 30px;
}

.c-workflow-steps {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.c-step {
    padding: 25px;
    background-color: var(--light-color);
    border-radius: 10px;
    text-align: center;
}

.c-step h5 {
    color: var(--primary-color);
    font-size: 1.2em;
    margin-bottom: 15px;
}

.c-step p {
    line-height: 1.5;
    color: var(--primary-color);
}

/* Footer */
.c-footer {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 5px 10%;
    border-top: 1px solid var(--light-color);
}

.c-footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.c-social-icons {
    display: flex;
    gap: 20px;
}

.c-social-icons a {
    color: var(--light-color);
    transition: opacity 0.3s ease;
}

.c-social-icons a:hover {
    opacity: 0.7;
}


/* Responsive Styles */

/* 1024px */
@media (max-width: 1024px) {
    .c-hero h1 {
        font-size: 3em;
    }

    .c-hero p {
        font-size: 1.2em;
    }

    .c-about-header h2,
    .c-projects-header h2 {
        font-size: 2.8em;
    }

    .c-mission h3,
    .c-vision h3 {
        font-size: 1.8em;
    }

    .c-core-values h3 {
        font-size: 2.2em;
    }

    .c-project-intro h3 {
        font-size: 1.8em;
    }

    .c-project-tagline,
    .c-project-overview {
        font-size: 1.1em;
    }

    .c-feature-column h4 {
        font-size: 1.2em;
    }

    .c-capability-card h5,
    .c-impact-card h5,
    .c-step h5 {
        font-size: 1.1em;
    }
}


@media (max-width: 1080px) {

    .c-navbar,
    .c-navbar-scrolled {
        padding: 15px 30px;
    }

    .c-navbar-scrolled {
        padding: 10px 30px;
    }

    .c-navbar-center {
        position: absolute;
        left: 50%;
        transform: translateX(50%);
        font-size: 1.5rem;
        font-weight: bold;
    }

    .c-hero-section {
        padding-top: 140px;
    }

    .c-nav-links{
        display: none;
    }
}


/* 768px */
@media (max-width: 768px) {
    .c-logo {
        font-size: 2rem;
    }

    .c-nav-links {
        font-size: 1.2rem;
    }

    .c-hero{
        padding: 0 5%;
        padding-top: 140px;
    }

    .c-hero h1 {
        font-size: 2.5em;
    }

    .c-hero p {
        font-size: 1em;
    }

    .c-about-header h2,
    .c-projects-header h2 {
        font-size: 2.2em;
    }

    .c-about-header p,
    .c-project-tagline,
    .c-project-overview {
        font-size: 1em;
    }

    .c-mission h3,
    .c-vision h3 {
        font-size: 1.6em;
    }

    .c-core-values h3 {
        font-size: 1.8em;
    }

    .c-project-intro h3 {
        font-size: 1.6em;
    }

    .c-features-grid,
    .c-capabilities-grid,
    .c-impact-grid,
    .c-workflow-steps {
        grid-template-columns: 1fr;
    }

    .c-mission-vision {
        grid-template-columns: 1fr;
    }

    .c-mission,
    .c-vision {
        padding: 20px;
    }
}

/* 430px */
@media (max-width: 430px) {
    .c-nav {
        flex-direction: column;
        gap: 20px;
    }

    .c-nav-links {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        font-size: 1.1rem;
    }

    .c-hero h1 {
        font-size: 2em;
    }

    .c-mission-vision {
        grid-template-columns: 1fr;
    }

    .c-mission,
    .c-vision {
        padding: 20px;
    }

    .c-values-quadrant {
        margin: 15px;
    }

    .c-value-item h4 {
        font-size: 1.3em;
    }

    .c-value-item p {
        font-size: 0.9em;
    }

    .c-workflow-steps {
        grid-template-columns: 1fr;
    }
}
.landing-page {
    background-color: var(--primary-color);
    color: var(--light-color);
    min-height: 100vh;
}

/* HERO SECTION */

.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 100vw;
    margin: 50px auto 150px;
}

.hero h1 {
    font-size: 5em;
    padding: 0 200px 0 200px;
    margin-bottom: 20px;
    line-height: 1;
    font-weight: bolder;
}

.hero p {
    font-size: 1.2em;
    padding: 0 300px 0 300px;
    margin: 0;
    line-height: 1.5;
}

/* FEATURE SECTION */

.feature-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 100px 70px 100px;
    max-width: 1200px;
    margin: auto;
}

.feature-text {
    flex: 1;
    padding-right: 100px;
}

.feature-text h2 {
    font-size: 3em;
    margin-bottom: 20px;
    padding-left: 50px;
}

.feature-text p {
    font-size: 1.2em;
    padding-left: 50px;
}

.feature-image {
    flex: 1;
    height: 500px;
    min-width: 400px;
    max-width: 500px;
    background-color: var(--light-color);
}

.feature-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* COMPANY STATS */

.company-stats {
    display: flex;
    justify-content: space-around;
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 50px 20px;
}

.stat-item {
    text-align: center;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stat-item h3 {
    font-size: 3em;
    margin: 0;
}

.stat-item p {
    font-size: 1.5em;
    margin: 0;
}

.gold-stars {
    color: gold;
    font-size: 1.5em;
}


/* Blog Section */
.blog-section {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 20px 50px 50px 50px;
    text-align: center;
}

.blog-section h2 {
    font-size: 3em;
    margin-bottom: 50px;
}

.blog-posts {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 50px;
    flex-wrap: wrap; 
}

.blog-post {
    background-color: var(--light-color);
    border-radius: 10px;
    overflow: hidden;
    width: 275px;
    text-align: left;
}

.blog-image {
    height: 180px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    color: #333;
}

.blog-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-size: 20px;
}

.blog-post h3 {
    color: var(--dark-color);
    padding: 15px;
    font-size: 1.2em;
}

.learn-more {
    display: block;
    padding: 0 15px 15px;
    color: var(--primary-color);
    text-decoration: none;
    cursor: pointer;
}

.view-all-button {
    display: inline-block;
    background-color: var(--light-color);
    color: var(--primary-color);
    padding: 20px 30px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
}

.view-all-button:hover {
    background-color: var(--secondary-color);
    color: var(--light-color);
    border: 2px solid var(--light-color);
}
/* MEDIA QUERIES 1024px */

@media (max-width: 1024px) {

    /* HERO SECTION */

    .hero h1 {
        font-size: 5em;
        padding: 0 70px 0 70px;
        margin-bottom: 20px;
        line-height: 1;
    }

    .hero p {
        font-size: 1.2em;
        padding: 0 150px 0 150px;
        margin: 0;
        line-height: 1.5;
    }

    /* FEATURE SECTION */

    .feature-section {
        max-width: 800px;
        padding: 40px 50px 150px 50px;
    }

    .feature-image {
        height: 400px;
        min-width: 350px;
    }

    .feature-text {
        padding-right: 50px;
    }

    .feature-text h2 {
        font-size: 2.5em;
        margin-bottom: 10px;
        padding-left: 30px;
    }

    .feature-text p {
        font-size: 1em;
        padding-left: 30px;
    }

    /* BLOG SECTION */
    .blog-posts {
        justify-content: space-between;
    }

    .blog-post {
        width: calc(50% - 30px); 
    }
}

/* MEDIA QUERIES 768px */

@media (max-width: 768px) {

    /* HERO SECTION */

    .hero h1 {
        font-size: 3rem;
        padding: 0 40px 0 40px;
    }

    .hero p {
        padding: 0 80px 0 80px;
    }

    /* FEATURE SECTION */

    .feature-section {
        max-width: 600px;
        padding: 20px 30px 150px 30px;
    }

    .feature-image {
        height: 200px;
        min-width: 150px;
    }

    .feature-text {
        padding-right: 30px;
    }

    .feature-text h2 {
        font-size: 1.5em;
        margin-bottom: 10px;
        padding-left: 20px;
    }

    .feature-text p {
        font-size: 1em;
        padding-left: 20px;
    }

    /* COMPANY STATS */

    .company-stats {
        flex-wrap: wrap;
    }

    .stat-item {
        flex: 50%;
    }

    .stat-item:nth-child(3) {
        flex: 100%;
        margin-top: 20px;
    }

    /* BLOG SECTION */
    .blog-posts {
        justify-content: center;
    }

    .blog-post {
        width: 100%; 
        max-width: 500px; 
        margin-bottom: 30px; 
    }
}

/* MEDIA QUERIES 430px */

@media (max-width: 430px) {

    /* HERO SECTION */

    .hero {
        margin: 50px auto 100px;
    }

    .hero p {
        padding: 0 30px 0 30px;
    }

    /* FEATURE SECTION */

    .feature-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 30px 10px 30px;
    }

    .feature-text {
        padding-right: 0;
        text-align: center;
    }

    .feature-image {
        order: 1;
        width: 100%;
        min-height: 200px;
        margin: 20px 0;
    }


    .feature-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }

    .feature-text h2 {
        order: 0;
        font-size: 1.5em;
        margin-bottom: 10px;
        padding-left: 0;
    }

    .feature-text p {
        order: 2;
        font-size: 1em;
        padding-left: 0;
    }

    /* COMPANY STATS */

    .company-stats {
        flex-direction: column;
        align-items: center;
    }

    .stat-item {
        flex: 100%;
        margin-bottom: 20px;
    }
}








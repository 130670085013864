/* ANNAMStyles.css */

.container {
    font-family: Arial, sans-serif;
    color: white;
    background-color: #1995AD;
}

/* Navbar styles */

.a-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 150px;
    background-color: var(--primary-color);
    color: var(--light-color);
    position: relative;
}

.a-navbar-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.a-navbar-logo {
    font-size: 2em;
    font-weight: bold;
    color: var(--light-color);
    text-decoration: none;
    transition: transform 0.3s ease, cursor 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 6px;
}

.a-navbar-logo:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.a-logo-text {
    font-size: 1.2em;
    line-height: 1;
}

.a-logo-subtext {
    font-size: 0.6em;
    letter-spacing: 0.1em;
}

.a-orange-u {
    color: #FF671F;
}

.a-navbar-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 2.5rem;
    font-weight: bold;
}

.a-nav-links {
    display: flex;
    gap: 20px;
    align-items: center;
}

.a-nav-links a {
    color: var(--light-color);
    text-decoration: none;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.5px;
}

.a-nav-links a:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
}

/* SCROLLABLE CODE FOR NAVBAR FOR ALL 5 PAGES OTHER THAN LANDING PAGE */

.a-navbar-scrolled {
    padding: 20px 150px;
    background-color: var(--light-color);
    /* color: #1A21FF; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.a-navbar-scrolled .a-navbar-logo {
    color: var(--primary-color);
}

.a-navbar-scrolled .a-logo-subtext {
    color: var(--dark-color);
}

.a-navbar-scrolled .a-navbar-center {
    color: var(--primary-color);
}

.a-navbar-scrolled .a-nav-links a{
    color: var(--primary-color);
}



/* Transition for smooth scrolling effect */
.a-navbar,
.a-navbar-scrolled,
.a-navbar-logo {
    transition: all 0.3s ease;
}

.landing-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem ;
    max-width: 1100px;
    margin: 0 auto;
    gap: 50px;
    padding-top: 200px;
}

.landing-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
}

.landing-title {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.landing-text {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    width: 80%;
}

.button {
    background-color: white;
    color: #1995AD;
    border: none;
    padding: 1rem 1.5rem;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
}

.button:hover {
    background-color: #E0FFFF;
}

.about-section {
    padding: 4rem 2rem;
    background-color: #1995AD;
    max-width: 1000px;
    margin: 0 auto;
}

.about-title {
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
}

.about-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    justify-items: center;
}

.about-card {
    background-color: white;
    color: #1995AD;
    padding: 1.75rem 3rem;
    border-radius: 0.5rem;
    text-align: center;
    max-width: 400px;
}

.card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 0.25rem;
}

.card-text {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
    line-height: 1.5;
}

.about-card:nth-child(3) {
    grid-column: 1 / span 2;
    justify-self: center;
}

.core-values {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 3rem 0;
}

.core-values-title {
    color: white;
}

.core-values img{
    width: 700px;
}

.projects-section {
    background-color: #1995AD;
    color: white;
    text-align: center;
    padding: 10px 20px;
    margin-bottom: 50px;
}

.projects-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 50px;
}

.project-subtitle {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 30px;
}

.project-description {
    font-size: 1.5rem;
    line-height: 1.8;
    max-width: 1200px;
    margin: 0 auto 30px;
    padding: 0 20px;
}

.project-timeline {
    padding: 2rem 0;
}

.project-phases {
    padding: 2rem 0;
}

.bhojanam-card {
    /* background-color: #17a2b8; */
    border-radius: 10px;
    border: solid white;
    padding: 1rem 3rem;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
    max-width: 1000px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 0;
}

.bhojanam-card:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(255, 255, 255, 0.1);
}

.bhojanam-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.bhojanam-description {
    font-size: 1.25rem;
    line-height: 1.5;
    text-align: left;
}

/* Footer */
.p-footer {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 5px 10%;
    border-top: 1px solid var(--light-color);
}

.p-footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.p-social-icons {
    display: flex;
    gap: 20px;
}

.p-social-icons a {
    color: var(--light-color);
    transition: opacity 0.3s ease;
}

.p-social-icons a:hover {
    opacity: 0.7;
}

/* Keywords Section Styles */
.keywords-section {
    background-color: #1995AD;
    padding: 5rem 4rem;
    text-align: center;
}

.keywords-title {
    color: white;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
}

.keywords-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.75rem;
}

.keyword-tag {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.875rem;
    transition: background-color 0.3s ease;
}

.keyword-tag:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .keywords-section {
        padding: 1.5rem 1rem;
    }

    .keywords-title {
        font-size: 1.25rem;
    }

    .keyword-tag {
        font-size: 0.75rem;
        padding: 0.4rem 0.8rem;
    }
}


/* Responsive Styles */
@media (max-width: 1024px) {
    .nav{
        padding: 1rem 2rem;
    }
    .landing-section {
        flex-direction: column;
        text-align: center;
        padding: 2rem 1rem;
        gap: 20px;
    }

    .landing-title {
        font-size: 3.5rem;
    }

    .landing-text {
        font-size: 1.25rem;
        width: 100%;
    }

    .about-card {
        max-width: 80%;
        margin: auto;
    }

    .bhojanam-card {
        flex-direction: column;
        padding: 1rem;
    }

    .about-card{
        padding: 1.55rem 1.75rem;
    }

    .about-grid{
        gap: 10px;
    }
}



@media (max-width: 1080px) {

    .a-navbar,
    .a-navbar-scrolled {
        padding: 15px 30px;
    }

    .a-navbar-scrolled {
        padding: 10px 30px;
    }

    .a-navbar-center {
        position: absolute;
        left: 50%;
        transform: translateX(50%);
        font-size: 1.5rem;
        font-weight: bold;
    }

    .a-hero-section {
        padding-top: 140px;
    }

    .a-nav-links{
        display: none;
    }
}



@media (max-width: 768px) {
    .nav {
        flex-direction: column;
        gap: 1rem;
    }

    .logo {
        font-size: 2rem;
    }

    .nav-links {
        font-size: 1.25rem;
        gap: 1.5rem;
    }

    .landing-section{
        padding-top: 140px;
    }

    .landing-title {
        font-size: 3rem;
    }

    .landing-text {
        font-size: 1.15rem;
    }

    .about-section {
        padding: 3rem 1rem;
    }

    .about-title {
        font-size: 2.5rem;
    }

    .projects-title {
        font-size: 2rem;
    }

    .bhojanam-card {
        gap: 10px;
        padding: 1.5rem;
    }

    .bhojanam-title {
        font-size: 2rem;
        margin-bottom: 10px;
    }

    .bhojanam-description {
        font-size: 1.15rem;
        line-height: 1.5;
        text-align: center;
    }

    .core-values img{
        width: 400px;
    }
}

@media (max-width: 430px) {
    .nav {
        flex-direction: column;
    }

    .logo {
        font-size: 1.5rem;
    }

    .nav-links {
        font-size: 1rem;
        gap: 1rem;
    }

    .landing-title {
        font-size: 2.5rem;
    }

    .landing-text {
        font-size: 1rem;
    }

    .button {
        padding: 0.75rem 1rem;
        font-size: 0.875rem;
    }

    .about-title, .projects-title, .core-values-title {
        font-size: 1.75rem;
    }

    .about-card, .bhojanam-card {
        padding: 1.5rem;
        font-size: 0.9rem;
    }
}
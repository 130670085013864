/* General Styles */
.about-page {
    background-color: var(--light-color);
    color: var(--secondary-color);
    min-height: 100vh;
    box-sizing: border-box;
}

/* About Content */
.about-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 500px;
    padding: 100px 5% 40px;
}

.about-content h1 {
    font-size: 4.5em;
    padding: 0 5%;
    margin-bottom: 20px;
    line-height: 1;
}

.about-content p {
    font-size: 1.2em;
    padding: 0 5%;
    margin: 0;
    line-height: 1.5;
}

/* What We Do */
.what-we-do {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 15%;
    flex-wrap: wrap;
    gap: 50px;
}

.what-we-do .text-content {
    flex: 1;
    min-width: 300px;
    padding-right: 20px;
}

.what-we-do h2 {
    font-size: 3em;
    margin-bottom: 20px;
}

.what-we-do p {
    font-size: 1.2rem;
}

/* .what-we-do .image {
    flex: 1;
    min-width: 200px;
    height: 350px;
    background-color: lightgray;
    display: grid;
    grid-template-areas:
        "img1 img2"
        "img3 img4";
    gap: 10px;
} */
.what-we-do .image {
    flex: 1;
    display: grid;
    grid-template-areas:
        "Img1 Img2"
        "Img3 Img4";
    gap: 10px;
    min-width: 200px;

}

.what-we-do .Img1 {
    grid-area: Img1;
    width: 100%;
    height: 100%;
}

.what-we-do .Img2 {
    grid-area: Img2;
    width: 100%;
    height: 100%;
}

.what-we-do .Img3 {
    grid-area: Img3;
    width: 100%;
    height: 100%;
}

.what-we-do .Img4 {
    grid-area: Img4;
    width: 100%;
    height: 100%;
}

.what-we-do img {
    object-fit: cover;
    /* Ensures images cover the grid area without stretching */
    width: 100%;
    /* Fills the entire grid area */
    height: 100%;
    /* Fills the entire grid area */
}

/* Mission and Vision */
.mission-vision {
    background-color: var(--primary-color);
    color: var(--light-color);
    text-align: center;
    padding: 80px 10%;
    font-size: 1.2rem;
}

/* Core Values Section */
.core-values {
    text-align: center;
    padding: 50px 5%;
}

.core-values h2 {
    font-size: 3rem;
    margin-bottom: 50px;
    text-align: center;
    color: var(--secondary-color);
}

.value-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
}

.value-card {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 20px;
    text-align: center;
    font-size: 1rem;
    height: 430px;
    width: 250px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 5px;
}

.value-card h4 {
    font-size: 1rem;
    margin-bottom: 10px;
}

.value-card p {
    font-size: 1rem;
    line-height: 1.5;
}

/* Meet The Team */
.meet-the-team {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 50px 5%;
    text-align: center;
}

.meet-the-team h2 {
    font-size: 3em;
    margin-bottom: 50px;
}

.team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.team-member-card {
    background-color: var(--light-color);
    border-radius: 10px;
    overflow: hidden;
    width: 200px;
}

.member-image {
    height: 200px;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    position: relative;
}

.member-image::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: var(--primary-color);
}

.member-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.member-info {
    color: var(--secondary-color);
    padding: 10px;
    text-align: left;
    border-radius: 0 0 10px 10px;
}

.member-info h3 {
    margin: 0;
    font-size: 1.2em;
}

.member-info p {
    margin: 5px 0 0;
    font-size: 1em;
}

/* Work With Us */
.work-with-us {
    background-color: var(--primary-color);
    color: var(--light-color);
    text-align: center;
    padding: 50px 5%;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.work-with-us h2 {
    margin-bottom: 20px;
}

.work-with-us p {
    max-width: 1000px;
    padding-bottom: 10px;
}

.rounded-button {
    background-color: transparent;
    color: var(--light-color);
    border: 2px solid var(--light-color);
    border-radius: 15px;
    padding: 12px 30px;
    font-size: 1.2rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
}

.rounded-button:hover {
    background-color: var(--light-color);
    color: var(--secondary-color);
}

.rounded-button:hover .button-icon {
    background-color: var(--secondary-color);
    color: var(--light-color);
}

.button-icon {
    margin-left: 10px;
    font-size: 1rem;
    background-color: var(--light-color);
    color: var(--secondary-color);
    border-radius: 50%;
    padding: 5px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .about-content h1 {
        font-size: 3.5em;
    }

    .what-we-do .text-content,
    .what-we-do .image {
        flex: 100%;
        padding-right: 0;
        margin-bottom: 20px;
    }

    /* .company-card {
        width: calc(50% - 20px);
    } */
}

@media (max-width: 768px) {
    .about-content h1 {
        font-size: 3rem;
    }

    .what-we-do h2 {
        font-size: 2.5em;
    }

    /* .company-card {
        width: 100%;
    } */

    .team-member-card {
        width: calc(50% - 10px);
    }
}

@media (max-width: 480px) {
    .about-content h1 {
        font-size: 2.5rem;
    }

    .what-we-do{
        padding: 5px;
        margin: 0;
        justify-content: center;
        text-align: center;
    }

    .what-we-do h2 {
        font-size: 2em;
    }

    .what-we-do .text-content{
        max-width: 100px;

    }

    .team-member-card {
        width: 100%;
    }
}
/* General Styles */
.writings-page {
    padding: 80px 5% 50px;
    background-color: var(--light-color);
    color: var(--secondary-color);
    min-height: 100vh;
    box-sizing: border-box;
}

/* Writings Content */
.writings-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 500px;
    padding: 60px 5% 60px;
}

.writings-content h1 {
    font-size: 70px;
    font-weight: 750;
    line-height: 94.4px;
    padding: 10px 10px;
    margin-bottom: 20px;
    line-height: 1;
}

.writings-content p {
    font-size: 1.2em;
    font-weight: bold;
    padding: 0% 10%;
    margin-bottom: 50px;
    line-height: 1.5;
}

.writings-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    /* Adds space between buttons */
}

.writings-buttons button {
    background-color: transparent;
    color: var(--primary-color);
    width: 150px;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    padding: 10px 50px;
    font-weight: 500;
    font-size: 1.5rem;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition:
        background-color 0.3s,
        color 0.3s;
}

.writings-buttons button:hover {
    background-color: var(--primary-color);
    color: var(--light-color);
}

.writings-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 800px;
    margin: 0 auto;
    transition: transform 0.3s ease;
}

.writing-card:hover {
    cursor: pointer;
    background-color: var(--secondary-color);
    transform: scale(1.05);
}

.writing-card {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 30px;
    border-radius: 10px;
    text-align: left;
    text-decoration: none;
}

.writing-card h2 {
    font-size: 24px;
    margin-bottom: 10px;
    margin-top: 0;
}

.writing-card p {
    font-size: 16px;
    margin-bottom: 10px;
}

.writing-info {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: var(--light-color);
    gap: 2px;
}

/* More button */
.more-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.more-button {
    background-color:var(--light-color);
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 1.2rem;
    cursor: pointer;
    transition:
        background-color 0.3s,
        color 0.3s;
}

.more-button:hover {
    border: 2px solid var(--light-color);
    background-color: var(--primary-color);
    color: var(--light-color);
}


.contribute-section {
    background-color: var(--primary-color);
    color: var(--light-color);
    padding: 60px 20px;
    text-align: center;
}

.contribute-section p {
    max-width: 800px;
    margin: 0 auto 40px;
    font-size: 18px;
    line-height: 1.6;
}

.contribute-button {
    background-color: transparent;
    border: 2px solid var(--light-color);
    color: var(--light-color);
    padding: 10px 30px;
    font-size: 16px;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.contribute-button:hover {
    background-color: var(--light-color);
    color: var(--secondary-color);
}


/* Responsive Styles */
@media (max-width: 1024px) {
    .writings-content h1 {
        font-size: 6em;
    }

    .writings-content p {
        font-size: 1em;
        padding: 0 5%;
    }

    .writings-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .writing-card h2 {
        font-size: 1.5rem;
    }


}

@media (max-width: 768px) {
    .writings-content h1 {
        font-size: 4em;
    }

    .writings-content p {
        font-size: 0.9em;
        padding: 0 5%;
    }

    .writings-buttons button {
        width: 200px;
        font-size: 1.5rem;
    }

    .writing-card {
        padding: 15px;
    }

    .writing-card h2 {
        font-size: 1.2rem;
    }

    .writing-card h3 {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .writings-content h1 {
        font-size: 2.5em;
    }

    .writings-content p {
        font-size: 0.8em;
        padding: 0 2%;
    }

    .writings-buttons button {
        width: 100%;
        font-size: 1.2rem;
        padding: 15px;
    }

    .writing-card {
        padding: 25px;
    }

    .writing-card h2 {
        font-size: 1rem;
    }

}